import { FunctionComponent } from 'react';
import { Container, Content01 } from '@/atoms';
import { TypePartnersSectionProps } from './types';

export const PartnersSection: FunctionComponent<TypePartnersSectionProps> = ({
  className = '',
  padding,
  title,
  description,
  children,
}: TypePartnersSectionProps) => (
  <Container padding={padding}>
    <div className={`flex flex-col items-center gap-10 md:gap-16 ${className}`}>
      <Content01 title={title} description={description} position="center" />
      {children}
    </div>
  </Container>
);

PartnersSection.displayName = 'PartnersSection';
