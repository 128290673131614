import { ParagraphProps } from "components/paragraph";
import Image from 'next/image';
import { PartnersSection } from '@/molecules';
import { getUrlFromMedia } from "@/utils/utils";

export function Logoblock({ paragraph }: ParagraphProps) {
    return (
        <PartnersSection
                description={paragraph.field_text}
                padding="px-[16px] py-[40px] md:px-[16px] md:py-[72px]"
                title={paragraph.field_title}
            >

                <div className="gap-2 columns-2 xl:flex xl:flex-row xl:gap-6">
                    {paragraph.field_logos.map((image: any, index: number) => (
                        <div
                            key={`partner-${index}`}
                            className="border-[1px] border-solid border-primary-100 pt-[30px] px-[45.13px] pb-[29.07px] mb-2"
                        >
                            <Image alt="" height={40.93} src={getUrlFromMedia(image) || ''} width={89.74} />
                        </div>
                    ))}
                </div>
            </PartnersSection> 
    );
}


