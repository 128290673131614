// import { DrupalLink } from "components/common/drupal-link";
// import { Media } from "components/common/media";

import { SliderHero } from '@/organisms';
import { Hero } from '@/molecules';
import { getUrlFromMedia } from "@/utils/utils";
import { ButtonVariant } from '@/atoms/button/types';
import { ParagraphProps } from "components/paragraph";

// import { isRelative } from "lib/utils";

export function ImageSlide({ paragraph, className }: ParagraphProps) {
    const title = paragraph.field_heading ? 'h2' : 'p';
    const darkMode = paragraph.field_dark_banner ? 'dark' : 'light'
    return (
        <SliderHero.Slide className={className}>
            <Hero
                {...({
                    backgroundImage: {
                        src: getUrlFromMedia(paragraph.field_default_image),
                        srcset: [
                            {
                                media: '(min-width: 2560px)',
                                src: getUrlFromMedia(paragraph.field_image_4xl),
                            },
                            {
                                media: '(min-width: 1920px)',
                                src: getUrlFromMedia(paragraph.field_image_3xl),
                            },
                            {
                                media: '(min-width: 1440px)',
                                src: getUrlFromMedia(paragraph.field_image_2xl),
                            },
                            {
                                media: '(min-width: 1280px)',
                                src: getUrlFromMedia(paragraph.field_image_xl),
                            },
                            {
                                media: '(min-width: 1024px)',
                                src: getUrlFromMedia(paragraph.field_image_l),
                            },
                            {
                                media: '(min-width: 768px)',
                                src: getUrlFromMedia(paragraph.field_image_m),
                            },
                            {
                                media: '(min-width: 640px)',
                                src: getUrlFromMedia(paragraph.field_image_s),
                            },
                            {
                                media: '(min-width: 375px)',
                                src: getUrlFromMedia(paragraph.field_image_xs),
                            },
                        ],
                    }
                })}

                theme={darkMode}
                classNameWrapper="pt-header"
            >
                <Hero.Title
                    as={title}
                    className="lg:w-[50%]">
                    {paragraph.field_title}
                </Hero.Title>
                <Hero.Description>{paragraph.field_plain_text ? paragraph.field_plain_text : ''}</Hero.Description>
                <Hero.Button
                    href={paragraph.field_cta.url}
                    variant={ButtonVariant.DISPLAY}
                    margin={true}
                >
                    {paragraph.field_cta.title}
                </Hero.Button>
            </Hero>
        </SliderHero.Slide>

    );
}