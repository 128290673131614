import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';

import { TypeScrolledGridProps } from './types';

export const ScrolledGrid: FunctionComponent<TypeScrolledGridProps> = ({
  className = '',
  numCol,
  minWidthElement,
  children,
  gap,
}: TypeScrolledGridProps) => (
  <div
    className={oneLine`
      flex items-center overflow-hidden justify-center mb-6 md:mx-auto before:absolute before:top-10 before:-translate-y-1/2 before:h-px before:left-0 -mx-4 
      ${className}
    `}
  >
    <div className="overflow-x-auto">
      <div
        className="grid px-4"
        style={{
          gridTemplateColumns: `repeat(${numCol}, minmax(0, 1fr))`,
          minWidth: `calc(${minWidthElement} * ${numCol})`,
          gap: gap,
        }}
      >
        {children}
      </div>
    </div>
  </div>
);

ScrolledGrid.displayName = 'ScrolledGrid';
