import { ParagraphProps } from "components/paragraph";
import { Container } from '@/atoms';
import { JamSchool } from '@/organisms';

export function ArticleBlock({ paragraph, view }: ParagraphProps) {
    return (
        <Container wrapper backgroundColor="bg-primary-50">
            <JamSchool
                cards={view}
                title={paragraph.field_title}
                description={paragraph.field_text?.value}
            />
        </Container>
    )
}


