import { FunctionComponent } from 'react';

import Container from '@/atoms/container';

import { toArray } from '@/lib/utils';

import { TypeContent02Props } from './types';

export const Content02: FunctionComponent<TypeContent02Props> = ({
  className = '',
  backgroundImage,
  backgroundTop,
  backgroundColor,
  children,
  overlay,
}: TypeContent02Props) => (
  <Container
    className={className}
    backgroundImage={backgroundImage}
    backgroundColor={backgroundColor}
    overlay={overlay}
    backgroundTop={backgroundTop}
    backgroundLazy={true}
    classNameWrapper="w-full h-full relative"
    wrapper
  >
    {toArray(children).map((child, index) => (
      <child.type {...child.props} key={index} />
    ))}
  </Container>
);

Content02.displayName = 'Content02';
