import { ParagraphProps } from "components/paragraph";
import { Container } from '@/atoms';
import ContentImage02 from '@/molecules/content-image-02';
import ContentNumber from '@/atoms/content-number';
import { Color as ColorNumber } from '@/atoms/content-number/types';
import { MoreInfo } from '@/molecules';
import { getUrlFromMedia } from "@/utils/utils";

export function OriginBlock({ paragraph }: ParagraphProps) {

    return (
        <>
            <Container wrapper>
                <ContentNumber
                    color={ColorNumber.DEFAULT}
                    className="opacity-50 absolute"
                >
                    01
                </ContentNumber>
                <MoreInfo title={paragraph.field_title} font="headline">
                    {paragraph.field_text.processed}
                </MoreInfo>
            </Container>

            {paragraph.field_zig_zag_items.length ? paragraph.field_zig_zag_items.map(((item, index) => (
                <Container wrapper padding='' wrapperSize='max-w-[1440px]' key={index}>

                    <ContentImage02
                        image={{
                            src: getUrlFromMedia(item.field_image),
                            alt: 'casa',
                        }}
                        index={index}
                        title={item.field_title}
                        backNumber={`0${index + 2}`}
                        description={item.field_text}
                        reverse={index % 2 != 0 ? true : false}
                    />
                </Container>
            ))) : null}

        </>
    );
}


