import { ParagraphProps } from "components/paragraph";

import { Container } from "@/atoms";
import ProductBlockShopify from "@/templates/product-block-shopify";
import { CardsPagination } from '@/organisms';
import { CardProduct } from '@/molecules';
import { Size, Variation } from "@/molecules/card-product/types";

export function ProductList({ paragraph, view }: ParagraphProps) {
    return (
        paragraph.field_slider ?
            <Container wrapper>
                <ProductBlockShopify
                    shopifyProducts={view}
                    noColumnas={paragraph.field_columns}
                    shopifyCategory={paragraph.field_tag.name}
                    title={paragraph.field_title}
                /> 
            </Container> :
            <Container wrapper>
                <CardsPagination>
                    {view?.map((item: any, index: number) => {
                        const tagElement = item.tags.flatMap((item: any) => {
                            if (item.name == 'jamonero') {
                                return (<CardProduct.Tag colors="info">
                                 con cuchillo y Jamonero
                                   </CardProduct.Tag>)
                            } else return []
                        });
                        return (<CardProduct
                            key={index}
                            image={{ src: item.image.uri.url }}
                            size={Size.MEDIUM}
                            variation={Variation.DISPLAY}
                            href={item.path?.alias}
                            itemListIndex={index}
                            itemListName={paragraph.field_tag.name}
                            inStock={item.variants[0].inventory_quantity > 0}
                            product={item}
                        >
                            {tagElement.length > 0 ? tagElement[0] : undefined}
                            <CardProduct.Title>{item.field_alternate_title ? item.field_alternate_title.toUpperCase() : item.title.toUpperCase()}</CardProduct.Title>
                            <CardProduct.Price
                                regular={item.variants[0].compare_at_price || item.variants[0].price}
                                {...(item.variants[0].compare_at_price && { sale: item.variants[0].price })}
                            />
                        </CardProduct>
                    )})}
                </CardsPagination>
            </Container>
    )
}


