import { oneLine } from 'common-tags';
import { FunctionComponent } from 'react';

import { Color, TypeContentNumberProps } from './types';

export const ContentNumber: FunctionComponent<TypeContentNumberProps> = ({
  className = '',
  color,
  children,
}: TypeContentNumberProps) => {
  const colorStroke =
    color === Color.NORMAL
      ? '[-webkit-text-stroke:1px_#1E1916] [-moz-text-stroke:1px_#1E1916]'
      : '[-webkit-text-stroke:1px_#C78938] [-moz-text-stroke:1px_#C78938]';
  return (
    <div className={` ${className}`}>
      <span
        className={oneLine`
        text-display text-[140px] lg:text-[167px] relative z-0 top-8 lg:top-6 left-18 sm:-left-32 text-transparent 
        ${colorStroke}
    `}
      >
        {children}
      </span>
    </div>
  );
};

ContentNumber.displayName = 'ContentNumber';
