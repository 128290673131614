import { FunctionComponent } from 'react';

import { Grid, Pagination } from '@/molecules';

import { toArray } from '@/utils';
import { usePagination } from '@/hooks';

import { TypeCardsPaginationProps } from './types';

export const CardsPagination: FunctionComponent<TypeCardsPaginationProps> = ({
  className = '',
  children,
}: TypeCardsPaginationProps) => {
  const { currentItems, currentPage, handlePageChange } = usePagination(
    toArray(children)
  );

  return (
    <div className={`flex flex-col gap-20 ${className}`}>
      <Grid xxs={1} sm={2} lg={3} className="gap-6">
        {currentItems.map((item, index) => (
          <Grid.Item key={index}>{item}</Grid.Item>
        ))}
      </Grid>

      <Pagination
        onPageChange={handlePageChange}
        pageCount={currentPage}
        className="mx-auto md:mr-0"
      />
    </div>
  );
};

CardsPagination.displayName = 'CardsPagination';
