import { ParagraphProps } from "components/paragraph";
import { Slide } from "components/slider";
import { DrupalParagraph } from "next-drupal";
import { SliderHero } from '@/organisms';

export function BannerSlider({ paragraph }: ParagraphProps) {
    return (
        <SliderHero minHeight="min-h-[550px] md:min-h-[550px]">
            {paragraph.field_slides?.length ? paragraph.field_slides.map((item: DrupalParagraph, index: number) => (
                <Slide key={index} paragraph={item} />
            )) : null}
        </SliderHero>
    )
}


