import { ParagraphProps } from "components/paragraph";
import { Features, SliderProduct } from '@/molecules';
import { TrazabilitySection } from '@/organisms';

export interface Icon {
    field_icon: {
        field_media_svg: {
            resourceIdObjMeta: {
                markup: string;
            };
        };
        field_link?: string,
    };
    field_plain_text: string;
    field_title: string;
}

export function IconSlider({ paragraph }: ParagraphProps) {
    return (
        <TrazabilitySection
            href=""
            link=""
            padding="px-[16px] py-[40px] md:px-[0px] md:py-[72px]"
            pretitle={paragraph.field_pretitle}
            title={paragraph.field_title}
        >
            <div className="flex flex-row text-center px-4 md:px-0">
                <SliderProduct
                    className='w-full'
                    breakpoints={{
                        500: {
                            padding: 45,
                            perPage: 1,
                        },
                        768: {
                            padding: 45,
                            perPage: 2,
                        },
                        1024: {
                            perPage: 3,
                        },
                    }}
                    perPage={4}
                >
                    {paragraph.field_complex_icon_items.length ? paragraph.field_complex_icon_items.map((icon: Icon, index: number) => (
                            <Features
                                svg={icon.field_icon.field_media_svg.resourceIdObjMeta.markup}
                                className="gap-8"
                                description={icon.field_plain_text}
                                position="center"
                                theme="light"
                                title={icon.field_title}
                                key={index}
                            />
                        )) : null}
                </SliderProduct>
            </div>
        </TrazabilitySection>
    );
}


