import { FunctionComponent } from 'react';
import Image from 'next/image';
import { oneLine } from 'common-tags';

//import { Text } from '@/atoms';
import { Prose, TwoColumn } from '@/molecules';

import { getUrlExtension, isPNG } from '@/lib/utils';

import { TypeContentImageProps } from './types';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

/**
 * ContentImage
 */
export const ContentImage: FunctionComponent<TypeContentImageProps> = ({
  className = '',
  reverse = false,
  image,
  description,
  title,
  square = false,
}: TypeContentImageProps) => {
  const srcWithFallback =
    image.src && image.src !== '' ? image.src : FallBackIMG.src;

  return (
    <TwoColumn
      reverse={reverse}
      gap="gap-8"
      className={oneLine`
      ${!reverse ? '!flex flex-col-reverse lg:flex-row' : ''}
      ${className}
    `}
    >
      <TwoColumn.Left
        className={`aspect-square md:w-full  ${!square ? 'md:h-[400px]' : ''
          }  relative  empty:hidden`}
      >
        <Image
          src={srcWithFallback}
          alt={image.alt ?? 'Content image'}
          className={oneLine`
            w-full h-full object-center absolute inset-0
            ${isPNG(getUrlExtension(srcWithFallback))
              ? 'object-contain'
              : 'object-cover'
            }
          `}
          draggable="false"
          sizes="100vw"
          fill
        />
      </TwoColumn.Left>

      <TwoColumn.Right className="flex flex-col items-start justify-center  empty:hidden">
        <div className={reverse ? 'xl:pr-[122px]' : 'xl:pl-[122px]'}>
          {title && (
            <h2 className="text-subtitle text-subtitle--l mb-6">{title}</h2>
          )}

          {description && (
            <Prose
              className="text-body text-[large] md:text-body md:text-body--l leading-[22px]"
              html={description}
            />
          )}
        </div>
      </TwoColumn.Right>
    </TwoColumn>
  );
};

ContentImage.displayName = 'ContentImage';
