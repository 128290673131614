import { FunctionComponent } from 'react';
import Link from 'next/link';
import { oneLine } from 'common-tags';
import Image from 'next/image';

import { ArrowRight } from '@/icons';

import { getUrlExtension, isPNG } from '@/lib/utils/utils';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import { TypeCardSectionProps } from './types';

/**
 * CardSection
 */
export const CardSection: FunctionComponent<TypeCardSectionProps> = ({
  className = '',
  title,
  description,
  image,
  url,
}: TypeCardSectionProps): JSX.Element => {
  const srcWithFallback = image && image !== '' ? image : FallBackIMG.src;

  return (
    <div
      className={oneLine`
        flex flex-col w-full p-3
        ${className}
      `}
    >
      <div className="relative w-full h-[240px] mb-5">
        
      <Link href={url} prefetch={false} aria-label="Leer más">
         <Image
          src={srcWithFallback}
          alt="Card Image"
          className={oneLine`
            w-full h-full object-center absolute inset-0
            ${
              isPNG(getUrlExtension(srcWithFallback))
                ? 'object-contain'
                : 'object-cover'
            }
          `}
          draggable="false"
          sizes="(max-width: 768px) 100vw,
          50vw"
          fill
        />
        </Link>
      </div>

      <div className="flex justify-between gap-4">
        <div>
          <Link href={url} prefetch={false} aria-label="Leer más">
            <h3 className="text-subtitle text-subtitle--l mb-3">{title}</h3>
            <p className="text-body text-body--m">{description}</p>
          </Link>
        </div>

        <div>
          <Link href={url} prefetch={false} aria-label="Leer más">
            <ArrowRight
              color="rgb(var(--tw-color-primary-500))"
              width={32}
              height={32}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

CardSection.displayName = 'CardSection';
