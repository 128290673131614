import { ParagraphProps } from "components/paragraph";
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';

import { Button, Container } from '@/atoms';
import { ButtonVariant } from '@/atoms/button/types';


export function PopUp({ paragraph }: ParagraphProps) {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        open && sessionStorage.setItem('popup', true);
    });
    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={() => setOpen(false)}
                >
                    <div className="min-h-screen px-4 text-center md:w-full bg-neutral-900">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        <span
                            className="inline-block md:h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="my-20 md:my-8 inline-block w-4/5 md:w-[800px]  md:h-[400px] transform  bg-white text-left md:align-middle shadow-xl transition-all">
                                <div className="flex flex-col md:flex-row">
                                    <div
                                        className="w1 md:w-1/2"
                                        style={{
                                            height: '402px',
                                            backgroundImage: `url(${paragraph.field_image.field_media_image.uri.url})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }}
                                    />
                                    <div className="w-full h-auto md:w-1/2 p-6 pt-0">
                                        <Container
                                            wrapper
                                            padding="pt-2"
                                            backgroundColor="bg-white"
                                        >
                                            <div className="modal-header flex flex-shrink-0 justify-end">
                                                <button
                                                    type="button"
                                                    className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    x
                                                </button>
                                            </div>
                                            <div className="grid col-span-2 md:pt-8 ">
                                                <p className="text-headline text-headline--h2 text-text-primary mt-3">
                                                    {paragraph.field_title}
                                                </p>
                                                <p className="ext-subtitle text-subtitle--l text-body text-body--m mt-5">
                                                    {paragraph.field_plain_text}
                                                </p>
                                                <div className="w-max mb-4 mt-9">
                                                    <Button
                                                        as="a"
                                                        variant={ButtonVariant.PRIMARY}
                                                        href={paragraph.field_cta.url}
                                                    >
                                                        {paragraph.field_cta.title}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Container>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

