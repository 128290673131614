import { ParagraphProps } from "components/paragraph";
import { Container } from '@/atoms';
import Prose from '@/molecules/prose';

export function FormattedTextBlock({ paragraph }: ParagraphProps) {

    return (
        <Container
            wrapper
            wrapperSize="max-w-[790px]"
            classNameWrapper="overflow-hidden"
        >
            <div>
                <Prose className="text-[large] md:text-base" html={paragraph.field_text} />
            </div>
        </Container >
    );
}