import { FunctionComponent } from 'react';
import { Container, Content01, Link } from '@/atoms';
import { TypeTrazabilitySectionProps } from './types';

export const TrazabilitySection: FunctionComponent<
  TypeTrazabilitySectionProps
> = ({
  className = '',
  pretitle,
  title,
  link,
  href,
  children,
}: TypeTrazabilitySectionProps) => (
  <Container classNameWrapper="w-full h-full relative" wrapper>
    <div className={`flex flex-col items-center gap-8 md:gap-16 ${className}`}>
      <Content01 pretitle={pretitle} title={title} position="center" />
      <div className="flex md:hidden">
        {link !== '' && (
          <Link href={href} icon="right">
            {link}
          </Link>
        )}
      </div>
      <div className="w-full">{children}</div>
      <div className="hidden md:block">
        {link !== '' && (
          <Link href={href} icon="right">
            {link}
          </Link>
        )}
      </div>
    </div>
  </Container>
);

TrazabilitySection.displayName = 'TrazabilitySection';
