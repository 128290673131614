import { TypeClassName, TypeImage } from '@/types';

export const enum Color {
  DEFAULT = 'DEFAULT',
  DISPLAY = 'DISPLAY',
  NORMAL = 'NORMAL',
  DARK = 'DARK',
  BEIGE = 'Beige'
}

export interface TypeContentImage02Props extends TypeClassName {
  /**
   * Imagen del contenido
   */
  image: TypeImage;
  /**
   * imagen del contenido abajo derecha
   */
  imageDrawing?: TypeImage;
  /**
   * Elegir imagen derecha izquierda
   */
  reverse?: boolean;
  /**
   * Título del contenido
   */
  title?: string;
  /**
   * Descripción
   */
  description?: string;
  /**
   * Indica el número de iteración para controlar el color
   */
  index: number;
  /**
   * Numero de fondo
   */
  backNumber?: string;
  /**
   * Texto del link
   */
  textLink?: string;
  /**
   * Url del link
   */
  urlLink?: string;
}
