import { ParagraphProps } from "components/paragraph";

import { getUrlFromMedia } from "@/utils/utils";
import { Container } from "@/atoms";
import { ContentImage } from "@/molecules";

export function ZigZagBlock({ paragraph }: ParagraphProps) {

    return (
        paragraph.field_zig_zag_items.map((element, index) => (
            <Container
                wrapper
                key={index}
            >
                <ContentImage
                    image={{
                        src: getUrlFromMedia(element.field_image),
                        alt: '',
                    }}
                    square
                    title={element.field_title}
                    description={element.field_text}
                    reverse={index % 2 == 0 ? true : false}
                />
            </Container >
        ))
    )
}


