import { ParagraphProps } from "components/paragraph";
import { Container, Content01 } from '@/atoms';
import TraceabilityWithModule from '@/organisms/traceability-with-module';
import { Form, Formik } from 'formik';
import { gtmPush } from '@/utils';
import { TextinputButton, TwoColumn } from '@/molecules';


export function ComplexCodeBlock({ paragraph }: ParagraphProps) {
    return (
        <>
            <TwoColumn className="min-h-[541px] w-full">
                <TwoColumn.Left className="bg-primary-600 md:pt-[130px] lg:pb-16 relative">
                    <Formik
                        initialValues={{
                            traceability: '',
                        }}
                        onSubmit={(data) => {
                            gtmPush({
                                'event': 'trazabilidad_producto',
                                'codigo_producto': data.traceability
                            });
                            setTimeout(() => window.open(
                                `/es/trazabilidad-jamon?bridle=${data.traceability}`, '_self'
                            ), 200)

                        }}
                    >
                        <Form>
                            <TraceabilityWithModule
                                description={paragraph.field_left_text}
                                padding="pt-[0px] py-6 md:pb-10 md:pb-2.5"
                                title={paragraph.field_left_title}
                                className="lg:max-w-[calc(var(--wrapper-size)/2)] lg:self-end lg:pr-[115px]"
                                colorText="dark"
                            >
                                <TextinputButton
                                    name="traceability"
                                    placeholder="Añade tu código"
                                />
                            </TraceabilityWithModule>
                        </Form>
                    </Formik>
                </TwoColumn.Left>
                <TwoColumn.Right className="bg-primary-500 py-16 md:py-[72px]">
                    <Container padding="px-4 lg:pl-0">
                        <div className="lg:max-w-[calc(var(--wrapper-size)/2)] w-full self-start lg:pl-[115px]">
                            <Content01
                                description={paragraph.field_text}
                                link={{
                                    href: paragraph.field_link.url,
                                    label: paragraph.field_link.title ? paragraph.field_link.title : 'Saber más',
                                }}
                                position="left"
                                pretitle={paragraph.field_pretitle}
                                theme="dark"
                                title={paragraph.field_title}
                                typeTitle="h2"
                            />
                        </div>
                    </Container>
                </TwoColumn.Right>
            </TwoColumn> 

        </>
    );
}


