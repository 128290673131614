import { Description, FeaturesMolecule, LinkAtom, Title } from './child';
import { Content02 } from './Content02';

export type TypeContent02 = typeof Content02 & {
  Title: typeof Title;
  Description: typeof Description;
  Link: typeof LinkAtom;
  Features: typeof FeaturesMolecule;
};

(Content02 as TypeContent02).Title = Title;
(Content02 as TypeContent02).Description = Description;
(Content02 as TypeContent02).Link = LinkAtom;
(Content02 as TypeContent02).Features = FeaturesMolecule;

Title.displayName = 'Content02.Title';
Description.displayName = 'Content02.Description';
LinkAtom.displayName = 'Content02.Link';
FeaturesMolecule.displayName = 'Content02.Features';

export type { TypeContent02Props } from './types';
export default Content02 as TypeContent02;
