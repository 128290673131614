import { FunctionComponent } from 'react';

import { TypeFeaturesProps } from './types';
import { ParseDrupalIcon } from '@/utils/parseDrupalIcon';

/**
 * Features
 */
export const Features: FunctionComponent<TypeFeaturesProps> = ({
  className = '',
  title,
  description,
  theme,
  position,
  svg
}: TypeFeaturesProps) => (
  <div
    className={`gap-2 ${position === 'center'
      ? 'flex flex-col items-center'
      : 'flex flex-row items-start'
      } ${className}`}
  >
    {ParseDrupalIcon({ icon: svg, className: "text-primary-500 w-16 h-16 md:w-20 md:h-20 mb-2" })}
    <div
      className={`gap-2 ${position === 'center' ? 'flex flex-col items-center text-center' : ''
        }`}
    >
      <p
        className={`\
        font-semibold text-body text-body--l w-[246px] sm:w-full\
         ${theme === 'light' ? 'text-text-primary' : 'text-text-alternative'}`}
      >
        {title}
      </p>
      <p
        className={`\
          text-body text-sm md:text-body--m w-[235px] sm:w-full
          ${theme === 'light' ? 'text-text-primary' : 'text-text-alternative'}\
        `}
      >
        {description}
      </p>
    </div>
  </div>
);

Features.displayName = 'Features';
