import { FunctionComponent } from 'react';

import { Container, Content01 } from '@/atoms';
import { CardSection, Grid } from '@/molecules';

import { TypeJamSchoolProps } from './types';
import { getUrlFromMedia } from '@/utils/utils';

/**
 * JamSchool
 */
export const JamSchool: FunctionComponent<TypeJamSchoolProps> = ({
  title,
  description,
  cards,
  className = '',
  theme,
}: TypeJamSchoolProps) => (
  <Container className={`bg-primary-50 ${className}`}>
    <Content01
      position="center"
      {...(theme && { theme })}
      {...(title && { title })}
      {...(description && { description })}
    />

    <Grid md={2} className="mt-8">
      {cards?.map((card: any, index: number) => (
        <Grid.Item key={index}>
          <CardSection
            className={card.className}
            description={card.field_summary}
            image={getUrlFromMedia(card.field_image)}
            title={card.title}
            url={card.path.alias}
          />
        </Grid.Item>
      ))}
    </Grid>
  </Container>
);

JamSchool.displayName = 'JamSchool';
