import { ParagraphProps } from "components/paragraph";
import { Container } from '@/atoms';
import TraceabilityWithModule from '@/organisms/traceability-with-module';
import { Form, Formik } from 'formik';
import { gtmPush } from '@/utils';
import { TextinputButton } from '@/molecules';


export function CodeBlock({ paragraph }: ParagraphProps) {
    return (
        <>
            <Container wrapper backgroundColor="bg-primary-50">
                <Formik
                    initialValues={{
                        traceability: '',
                    }}
                    onSubmit={(data) => {
                        gtmPush({
                            'event': 'trazabilidad_producto',
                            'codigo_producto': data.traceability

                        });
                        setTimeout(() => window.open(
                            `/es/trazabilidad-jamon?bridle=${data.traceability}`, "_self"
                        ), 200)
                    }}
                >
                    <Form>
                        <TraceabilityWithModule
                            backgroundColor="bg-primary-50"
                            backgroundColor2="bg-bg-quinary"
                            description={paragraph.field_text}
                            padding="pt-[0px] px-[16px] pb-[40px] md:px-[72px] md:pb-[10px]"
                            title={paragraph.field_title}
                            colorText="light"
                        >
                            <TextinputButton
                                name="traceability"
                                placeholder="Añade tu código"
                            />
                        </TraceabilityWithModule>
                    </Form>
                </Formik>
            </Container>

        </>
    );
}


