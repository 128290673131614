import { FunctionComponent } from 'react';
import { Container, Content01 } from '@/atoms';
import { TypeTraceabilityWithModuleProps } from './types';

export const TraceabilityWithModule: FunctionComponent<
  TypeTraceabilityWithModuleProps
> = ({
  className = '',
  padding,
  backgroundColor,
  backgroundColor2,
  title,
  description,
  children,
  colorText,
}: TypeTraceabilityWithModuleProps) => (
  <Container backgroundColor={backgroundColor} padding={padding}>
    <Container backgroundColor={backgroundColor2}>
      <div
        className={`flex flex-col items-center justify-center gap-6 md:gap-10 ${className}
        }`}
      >
        <Content01
          title={title}
          description={description}
          className="items-center text-center gap-4"
          theme={colorText}
        />
        <div>{children}</div>
      </div>
    </Container>
  </Container>
);

TraceabilityWithModule.displayName = 'TraceabilityWithModule';
