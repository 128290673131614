
import { ParagraphProps } from "components/paragraph";
import { VideoSlide } from "components/slides/video-slide";
import { ImageSlide } from "components/slides/image-slide";

const slideTypes = {
    "paragraph--image_slide": ImageSlide,
    "paragraph--video_slide": VideoSlide,
};

export function Slide({ paragraph, ...props }: ParagraphProps) {
    const Component = slideTypes[paragraph?.type];

    if (!paragraph || !Component) {
        return null;
    }

    return (
        <Component paragraph={paragraph} {...props} />
    )
}