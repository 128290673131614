import { ParagraphProps } from "components/paragraph";
import { Container } from '@/atoms';
import { ScrolledGrid } from '@/molecules';
import {
  Content,
} from '@/organisms';
import { Icon } from "./icon-slider";



export function IconBlock({ paragraph }: ParagraphProps) {
  return (
    <Container
      wrapper
      backgroundColor="bg-bg-primary"
      backgroundImage="/images/bgaboutus.png"
    >
      <div className="flex flex-col gap-6 md:flex-row md:gap-16">
        <div className="flex flex-col justify-center gap-4 md:w-1/2">
          <Content.Title>{paragraph.field_title}</Content.Title>
          <Content.Description>{paragraph.field_text}</Content.Description>
        </div>
        <ScrolledGrid
          numCol={3}
          gap={32}
          minWidthElement="210px"
          className="z-10"
        >
          {paragraph.field_complex_icon_items?.length ? paragraph.field_complex_icon_items.map((item: Icon, index: number) => (
              <Content.Features
                key={index}
                svg={item.field_icon.field_media_svg.resourceIdObjMeta.markup}
                title={item.field_title}
                description={item.field_plain_text}
                position="center"
                theme="dark"
              />
            )) : null}
        </ScrolledGrid >
      </div>
    </Container >

  )
}


