import { FunctionComponent } from 'react';

import { Link } from '@/atoms';
import Features from '@/molecules/features';

import { TypeButtonLink, TypeFeatures } from './types';
import { TypeChildren } from '@/types';
import { ParseText } from '@/utils/parseText';

export const Title: FunctionComponent<TypeChildren> = ({
  children,
}: TypeChildren) => (
  <span className="text-left text-headline text-headline--h2 text-text-alternative">
    {children}
  </span>
);

export const Description: FunctionComponent<TypeChildren> = ({
  children,
}: TypeChildren) => (
  <span className="text-left text-body text-body--m text-text-alternative"><ParseText html={children} /></span>
);

export const LinkAtom: FunctionComponent<TypeButtonLink> = ({
  children,
  href,
  variant,
  disabled,
  icon,
}: TypeButtonLink) => (
  <Link href={href} variant={variant} icon={icon} disabled={disabled}>
    {children}
  </Link>
);

export const FeaturesMolecule: FunctionComponent<TypeFeatures> = ({
  title,
  description,
  theme,
  position,
  svg,
  className,
}: TypeFeatures) => (
  <Features
    title={title}
    description={description}
    position={position}
    theme={theme}
    svg={svg}
    className={className}
  />
);
