import { TypeChildren, TypeClassName } from '@/types';

export const enum Color {
  DEFAULT = 'DEFAULT',
  DISPLAY = 'DISPLAY',
  NORMAL = 'NORMAL',
  DARK = 'DARK',
}

export interface TypeContentNumberProps extends TypeClassName, TypeChildren {
  /**
   * Indica el color de fondo
   */
  color: Color;
}
