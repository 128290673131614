import { DrupalNode, DrupalParagraph } from "next-drupal";
import { FormattedTextBlock } from "components/paragraphs/formatted-text-block";
import { PopUp } from "components/paragraphs/popup";
import { OriginBlock } from "components/paragraphs/origin-block";
import { ComplexCodeBlock } from "components/paragraphs/complex-code-block";
import { CodeBlock } from "components/paragraphs/code-block";
import { IconBlock } from "components/paragraphs/icon-block";
import { TraceabilityBlock } from "components/paragraphs/traceability-block";
import { Logoblock } from "components/paragraphs/logo-block";
import { BannerSlider } from "components/paragraphs/banner-slider";
import { Banner } from "components/paragraphs/banner";
import { LiveShoppingBlock } from "components/paragraphs/live-shopping-block";
import { IconSlider } from "components/paragraphs/icon-slider";
import { WebformBlock } from "components/paragraphs/webform-block";
import { ArticleBlock } from "components/paragraphs/article-block";
import { ZigZagBlock } from "components/paragraphs/zig-zag-block";
import { ProductList } from "@/components/paragraphs/product-list";

const paragraphTypes = {
    "paragraph--formatted_text_block": FormattedTextBlock,
    "paragraph--popup": PopUp,
    "paragraph--origin_block": OriginBlock,
    "paragraph--complex_code_block": ComplexCodeBlock,
    "paragraph--code_block": CodeBlock,
    "paragraph--icon_block": IconBlock,
    "paragraph--traceability_block": TraceabilityBlock,
    "paragraph--logo_block": Logoblock,
    "paragraph--banner_slider": BannerSlider,
    "paragraph--banner": Banner,
    "paragraph--live_shopping_block": LiveShoppingBlock,
    "paragraph--icon_slider": IconSlider,
    "paragraph--webform_block": WebformBlock,
    "paragraph--article_block": ArticleBlock,
    "paragraph--zig_zag_block": ZigZagBlock,
    "paragraph--product_list": ProductList
};

export interface ParagraphProps {
    paragraph: DrupalParagraph,
    id?: string,
    view?: DrupalNode[],
    className?: string
}

export function Paragraph({ paragraph, view, ...props }: ParagraphProps) {
    const Component = paragraphTypes[paragraph?.type];
    if (!paragraph || !Component) {
        return null;
    }

    return <Component paragraph={paragraph} view={view} {...props} />;
}