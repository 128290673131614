import { FunctionComponent } from 'react';
import { Container, Content01, Link } from '@/atoms';
import TwoColumn from '../two-column';

import { TypeTraceabilityBenefitsProps } from './types';

export const TraceabilityBenefits: FunctionComponent<
  TypeTraceabilityBenefitsProps
> = ({
  className = '',
  padding,
  padding2,
  backgroundColor,
  backgroundColor2,
  backgroundImage,
  title,
  description,
  link,
  href,
  children,
}: TypeTraceabilityBenefitsProps) => (
  <Container padding={padding} backgroundColor={backgroundColor}>
    <Container
      padding={padding2}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor2}
    >
      <TwoColumn className={`flex flex-col gap-8 md:gap-4 ${className}`}>
        <TwoColumn.Left className="flex flex-col gap-4">
          <Content01
            title={title}
            description={description}
            className="lg:w-[423px]"
          />
          {(link && href) ?
          <Link href={href} icon="right">
            {link}
          </Link>
          : undefined}
        </TwoColumn.Left>
        <TwoColumn.Right>{children}</TwoColumn.Right>
      </TwoColumn>
    </Container>
  </Container>
);

TraceabilityBenefits.displayName = 'TraceabilityBenefits';
