import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';

import { TypeGridProps } from './types';

/**
 * Grid
 */
export const Grid: FunctionComponent<TypeGridProps> = ({
  className = '',
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  children,
}: TypeGridProps) => (
  <div
    className={oneLine`
      grid grid-flow-row-dense
      ${xxs ? `grid-cols-${xxs}` : ''}
      ${xs ? `xs:grid-cols-${xs}` : ''}
      ${sm ? `sm:grid-cols-${sm}` : ''}
      ${md ? `md:grid-cols-${md}` : ''}
      ${lg ? `lg:grid-cols-${lg}` : ''}
      ${xl ? `xl:grid-cols-${xl}` : ''}
      ${xxl ? `2xl:grid-cols-${xxl}` : ''}
      ${className}
    `}
  >
    {children}
  </div>
);
