import { FunctionComponent } from 'react';
import NextLink from 'next/link';
import dynamic from 'next/dynamic';

import { Container, Content01, Link } from '@/atoms';

import { ContactHeroProps } from './types';
import { LinkVariant } from '@/atoms/link/types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const ContactForm = dynamic(() =>
  import('@/molecules').then((mod) => mod.ContactForm)
);

/**
 * Primary UI component for user interaction
 */
export const ContactHero: FunctionComponent<ContactHeroProps> = ({
  image,
  urlLink,
  textLink,
  pretitle,
  title,
  desciption,
  titleForm,
  nameLabel,
  surnameLabel,
  emailLabel,
  messageLabel,
  labelCkeckDown,
  textButton,
  className,
  classNameWrapper,
}: ContactHeroProps) => (
  <Container
    align="items-center"
    backgroundImage={image.src}
    justify="justify-center"
    wrapper
    overlay={0.7}
    classNameWrapper={classNameWrapper}
    className={className}
  >
    <div className="w-full relative flex items-center xl:items-start xl:justify-between pt-5 z-20 flex-col">
      <div className="w-full sm:w-[485px] pt-6 xl:pt-5 mb-11">
        <Content01
          description={desciption}
          {...(textLink &&
            urlLink && {
            link: {
              label: textLink,
              href: urlLink,
            },
          })}
          pretitle={pretitle}
          theme="dark"
          title={title}
          typeTitle="h1"
          className="items-center xl:items-start text-center xl:text-left"
        />
      </div>

      <div className="w-full flex justifyF-center xl:justify-start">
        <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
          <ContactForm
            backgroundColor="#ffffff"
            title={titleForm}
            className="px-4 py-6 md:p-14 w-full h-full"
            emailLabel={emailLabel}
            labelCkeckDown={labelCkeckDown}
            surnameLabel={surnameLabel}
            messageLabel={messageLabel}
            nameLabel={nameLabel}
            textButton={textButton}
          >
            <p>
              He leído y acepto la{' '}
              <NextLink
                href="/es/politica-privacidad"
                prefetch={false}
                passHref
                legacyBehavior
              >
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="!leading-5"
                  variant={LinkVariant.POSITIVE}
                >
                  política de privacidad.
                </Link>
              </NextLink>
            </p>
          </ContactForm>
        </GoogleReCaptchaProvider>
      </div>
    </div>
  </Container>
);
