import { ParagraphProps } from "components/paragraph";
import { TraceabilityBenefits } from '@/molecules';


export function TraceabilityBlock({ paragraph }: ParagraphProps) {
    return (
        <TraceabilityBenefits
            backgroundColor="white"
            backgroundColor2="bg-bg-quinary"
            backgroundImage="/assets/traceability-benefits.png"
            description={paragraph.field_text}
            href={paragraph.field_link?.url}
            link={paragraph.field_link?.title}
            padding="px-4 md:px-[40px] py-[16px] md:pt-10 md:pb-2 md:px-20"
            padding2="px-[40px] py-[24px] md:p-16"
            title={paragraph.field_title}
        >
            <div className="flex flex-col gap-6">
                {paragraph.field_properties.map((itm: { field_title: string, field_plain_text: string }, index: number) => (
                    <div
                        key={`item-${index}`}
                        className="flex flex-col border-l-[1px] border-solid border-primary-500 pt-2 pr-0 pb-2 pl-8"
                    >
                        <span className="text-subtitle text-subtitle--m text-text-primary">
                            {itm.field_title}
                        </span>
                        <span className="text-body text-body--m text-text-primary">
                            {itm.field_plain_text}
                        </span>
                    </div>
                ))}
            </div>
        </TraceabilityBenefits>
    );
}


