


import { SliderHero } from '@/organisms';
import { Hero } from '@/molecules';
import { ButtonVariant } from '@/atoms/button/types';
import { ParagraphProps } from 'components/paragraph';


export function VideoSlide({ paragraph, className }: ParagraphProps) {
    const title = paragraph.field_heading ? 'h2' : 'p';
    const darkMode = paragraph.field_dark_banner ? 'dark' : 'light'
    const video265 = paragraph.field_video_h265 ? paragraph.field_video_h265 : paragraph.field_video_h264
    return (
        <SliderHero.Slide className={className}>
            <Hero
                {...({
                    backgroundVideo: {
                        desktop: [
                            {
                                src: paragraph.field_video_h264.field_media_video_file.uri.url,
                                type: 'video/mp4',
                            },
                            {
                                src: video265.field_media_video_file.uri.url,
                                type: 'video/mp4; codecs=hevc',
                            },
                        ],
                    },
                    overlay: 0.5
                })}
                theme={darkMode}
                classNameWrapper="pt-header"
            >
                <Hero.Title
                    as={title}
                    className="lg:w-[50%]">
                    {paragraph.field_title}
                </Hero.Title>
                <Hero.Description>{paragraph.field_plain_text}</Hero.Description>
                <Hero.Button
                    href={paragraph.field_cta.url}
                    variant={ButtonVariant.DISPLAY}
                    margin={true}
                >
                    {paragraph.field_cta.title}
                </Hero.Button>
            </Hero>
        </SliderHero.Slide>
    );
}