import { FunctionComponent } from 'react';
import ReactPaginate from 'react-paginate';
import { oneLine } from 'common-tags';

import { CaretLeft, CaretRight } from '@/icons';

import { TypePaginationProps } from './types';

export const Pagination: FunctionComponent<TypePaginationProps> = ({
  className = '',
  disabled = false,
  onPageChange,
  pageRangeDisplayed = 4,
  pageCount,
  renderOnZeroPageCount = () => null,
}: TypePaginationProps) => (
  <ReactPaginate
    previousLabel={
      <CaretLeft
        width={24}
        height={24}
        className="flex flex-row gap-3 items-center text-primary-600"
      />
    }
    nextLabel={
      <CaretRight
        width={24}
        height={24}
        className="flex flex-row gap-3 items-center text-primary-600"
      />
    }
    pageClassName="flex flex-row gap-3 items-center justify-center p-1 text-center h-10 w-10 text-body text-body--l"
    activeClassName="flex flex-row gap-3 items-center bg-primary-500 text-text-alternative text-body text-body--l cursor-pointer"
    disabledClassName="pointer-events-none text-text-alternative opacity-40"
    onPageChange={onPageChange}
    pageRangeDisplayed={pageRangeDisplayed}
    pageCount={pageCount}
    renderOnZeroPageCount={renderOnZeroPageCount}
    marginPagesDisplayed={0}
    breakLabel={null}
    className={oneLine`
      flex flex-row gap-3 items-center pagination-number
      ${disabled ? 'pointer-events-none opacity-40' : ''} 
      ${className}
    `}
  />
);

Pagination.displayName = 'Pagination';
