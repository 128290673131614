import { ParagraphProps } from "components/paragraph";
import { Hero } from '@/molecules';
import { LinkVariant } from '@/atoms/link/types';
import { getUrlFromMedia } from "@/utils/utils";
import Container from "@/atoms/container";

export function Banner({ paragraph }: ParagraphProps) {
    return (
        <>
            {paragraph.field_link ?
                <Hero
                    align="items-center"
                    backgroundImage={getUrlFromMedia(paragraph.field_image)}
                    overlay={0.5}
                    textAlign="text-center"
                    theme="dark"
                    classNameWrapper="pt-header"
                >
                    {paragraph.field_icon ?
                        <Hero.Image src={`${getUrlFromMedia(paragraph.field_icon)}`} width={58} height={51} key={0} />
                        : null}
                    <Hero.Pretitle position="center" theme="dark">
                        {paragraph.field_pretitle}
                    </Hero.Pretitle>
                    <Hero.Title as="h1">
                        {paragraph.field_title}
                    </Hero.Title>
                    {paragraph.field_plain_text ?
                        <Hero.Description key={3}>
                            {paragraph.field_plain_text}
                        </Hero.Description> :
                        null}
                    <Hero.Link href={`${paragraph.field_link.url}`} variant={LinkVariant.NEGATIVE} key={3}>
                        {paragraph.field_link.title}
                    </Hero.Link>
                </Hero>
                :
                <Container
                    backgroundImage={getUrlFromMedia(paragraph.field_image)} overlay={0.5}>
                    <Hero
                        align="items-center"
                        textAlign="text-center"
                        theme="dark"
                        classNameWrapper="pt-[var(--header-height)]"
                    >
                        {paragraph.field_icon ?
                            <Hero.Image src={`${getUrlFromMedia(paragraph.field_icon)}`} width={58} height={51} key={0} />
                            : null}
                        <Hero.Pretitle position="center" theme="dark">
                            {paragraph.field_pretitle}
                        </Hero.Pretitle>
                        <Hero.Title as="h1">
                            {paragraph.field_title}
                        </Hero.Title>
                        {paragraph.field_plain_text ?
                            <Hero.Description key={3}>
                                {paragraph.field_plain_text}
                            </Hero.Description> : null}
                    </Hero>
                </Container>
            }
        </>
    )
}


