import { useEffect, useState } from 'react';

import { TypeEventPageChange } from '@/molecules/pagination/types';

type TypeConfigPagination = {
  /**
   * Number of items per page
   */
  perPage?: number;
  /**
   * Initial page
   */
  page?: number;
  /**
   * Initial offset
   */
  offset?: number;
};

type TypePagination<T> = {
  /**
   * Current items
   */
  currentItems: T[];
  /**
   * Current page
   */
  currentPage: number;
  /**
   * Event to change page
   */
  handlePageChange: (event: TypeEventPageChange) => void;
};

const DEFAULT_CONFIG = {
  perPage: 9,
  page: 0,
  offset: 0,
};

/**
 * Hook to manage pagination
 * @param {T[]} data Data to paginate
 * @param {TypeConfigPagination} config Configuration for pagination
 * @returns {TypePagination} Pagination data
 */
export const usePagination = <T>(
  data: T[],
  config?: TypeConfigPagination
): TypePagination<T> => {
  const {
    perPage = DEFAULT_CONFIG.perPage,
    page = DEFAULT_CONFIG.page,
    offset = DEFAULT_CONFIG.offset,
  } = config || {};

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(page);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(offset);
  const itemsPerPage = perPage;

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setCurrentPage(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  // Invoke when user click to request another page.
  const handlePageChange = (event: TypeEventPageChange) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    currentItems,
    currentPage,
    handlePageChange,
  };
};
