import { Container } from "@/atoms";
import { ParagraphProps } from "components/paragraph";
import Script from 'next/script';

export function LiveShoppingBlock({ paragraph }: ParagraphProps) {
    return (
        <Container wrapper
            classNameWrapper="pt-[60px] live-shopping"
            backgroundColor="bg-primary-100">

            <div>
                <div id="your_container" />
                <Script id="script-event">
                    {`
                (function () {       
                  var spck = {
                  storeId: ${paragraph.field_spockee_api_key}, // Your Store Id
      
                  customColor: '#000000',
      
                  containerId: 'your_container',
      
                  displayType: 'all',
      
                  staging: ${process.env.LIVE_SHOPPING_STAGE},
      
                  keywords: [],
      
                  transactionType: 'addToCart',
              };
      
              var el = document.createElement('script');
      
              el.setAttribute('src', 'https://party.spockee.io/builder/' + spck.storeId);
      
              el.setAttribute('data-spck', JSON.stringify(spck));
      
              document.body.appendChild(el);

      
              })();
      `}
                </Script>
            </div>
        </Container>
    );

}


