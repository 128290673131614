import { FunctionComponent } from 'react';
import Image from 'next/image';
import { oneLine } from 'common-tags';

import { Link, Text } from '@/atoms';
import { Prose, TwoColumn } from '@/molecules';

//import { getUrlExtension, isPNG } from '@/lib/utils';

import { Color, TypeContentImage02Props } from './types';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';
import { LinkVariant } from '@/atoms/link/types';


export const ContentImage02: FunctionComponent<TypeContentImage02Props> = ({
  className = '',
  reverse = false,
  image,
  backNumber,
  title,
  description,
  index,
  textLink,
  urlLink,
}: TypeContentImage02Props) => {
  const srcWithFallback =
    image.src && image.src !== '' ? image.src : FallBackIMG.src;

  const BACKGROUNDCOLOR_MAPS: Record<Color, string> = {
    [Color.DISPLAY]: 'bg-primary-50 ',
    [Color.NORMAL]: 'bg-primary-500 ',
    [Color.DARK]: 'bg-secondary-900 ',
    [Color.BEIGE]: 'bg-bg-quinary',
    [Color.DEFAULT]: 'bg-white',
  };
  const color = Object.keys(BACKGROUNDCOLOR_MAPS)[index];
  const colorText =
    color === Color.NORMAL ||
      color === Color.DARK
      ? 'text-primary-50'
      : 'text-black';

  const colorStroke =
    color === Color.NORMAL
      ? '[-webkit-text-stroke:1px_#1E1916] [-moz-text-stroke:1px_#1E1916]'
      : '[-webkit-text-stroke:1px_#C78938] [-moz-text-stroke:1px_#C78938]';

  return (
    <TwoColumn
      reverse={reverse}
      className={oneLine`
        ${!reverse
          ? '!flex flex-col lg:flex-row'
          : '!flex flex-col-reverse lg:flex-row'
        }
        ${className}
      `}
    >
      <TwoColumn.Left className="aspect-square lg:w-full h-[200px] lg:h-[520px] relative empty:hidden">
        {srcWithFallback !== '' && (
          <Image
            src={srcWithFallback}
            alt={image.alt ?? 'Content image'}
            fill
            // objectFit={isPNG(getUrlExtension(image.src)) ? 'contain' : 'cover'}
            // objectPosition="center"
            draggable="false"
          />
        )}
      </TwoColumn.Left>
      <TwoColumn.Right
        className={oneLine`
           flex flex-col items-center justify-center relative h-full lg:h-[520px] empty:hidden
           ${colorText}   
           ${BACKGROUNDCOLOR_MAPS[color]}   
         `}
      >
        {backNumber && (
          <Text
            className={oneLine`
              text-display text-[140px] lg:text-[167px] relative z-0 top-10 lg:top-1 -left-16 sm:-left-40 text-transparent
        ${colorStroke}
            `}
          >
            {backNumber}
          </Text>
        )}
        <div
          className={oneLine`
            relative z-0 bottom-9 px-8 sm:px-0 w-full sm:w-[385px]
          `}
        >
          {title && (
            <Text className="text-headline text-headline--h2 mb-6">
              {title}
            </Text>
          )}

          {description && (
            <Prose
              className="text-body text-[large] lg:text-body lg:text-body--l"
              html={description}
            />
          )}
          {textLink && (
            <Link
              href={urlLink}
              icon="right"
              variant={LinkVariant.POSITIVE}
              className="mt-3.5 lg:mt-7"
            >
              {textLink}
            </Link>
          )}
        </div>
      </TwoColumn.Right >
    </TwoColumn >
  );
};

ContentImage02.displayName = 'ContentImage02';
